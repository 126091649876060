
import { defineComponent, computed, ref, reactive, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Pagination from "@/components/Pagination.vue";
import {
  showErrorPopup,
  showSuccessPopup,
  isUndefined,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import moment from 'moment';
import useWebhookOutboundList from "@/core/services/compositions/webhook/useWebhookOutboundList";
import { ElNotification } from 'element-plus'
import ApiService from "@/core/services/ApiService";
import { checkPermission } from '@/router/authentication';
import FilterWebhook from "./filter-webhook.vue";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    VueJsonPretty,
    FilterWebhook
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isResend = ref<boolean>(false);
    const dialogVisible = ref(false);
    const idWebhook = ref();

    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("webhook.breadcrumb"), [
      { title: t("webhook.title") }
    ]);

    const paginationRef = ref(null);
    const { query } = useQuery();

    const form = reactive({
      endpoint: '',
      status_code: '',
      response_code: '',
      order_by: 'created_at',
      order: 'DESC',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      endpoint: query.value.endpoint || form.endpoint,
      status_code: query.value.status_code || form.status_code,
      response_code: query.value.response_code || form.response_code,
      date: query.value.date || form.date,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useWebhookOutboundList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          endpoint: '',
          status_code: '',
          response_code: '',
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const showDialog = (id) => {
      dialogVisible.value = true;
      idWebhook.value = id;
    }

    const resend = () => {
      isResend.value = true;
      dialogVisible.value = false;

      ApiService.setHeader();
      ApiService.post(
        `app/webhook/${idWebhook.value}/resend`,
        {}
      )
        .then(() => {
          showSuccessPopup({
            title: 'Resend Webhook',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          isResend.value = false;
        });

    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        endpoint: form.endpoint,
        status_code: form.status_code,
        date: form.date,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      isUndefined,
      handleChangePage,
      paginationRef,
      moment,
      resend,
      isResend,
      dialogVisible,
      showDialog,
      checkPermission,
      filtered
    };

  }
});

