
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";

interface Filter {
  endpoint: string;
  status_code: string;
  response_code: string;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-invocation",
  components: {},
  setup(props, context) {
    const { query } = useQuery();

    const data = reactive<Filter>({
      endpoint: query.value.endpoint || '',
      status_code: query.value.status_code || '',
      response_code: query.value.response_code || '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.endpoint = '';
      data.status_code = '';
      data.response_code = '';
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    return {
      data,
      filter,
      reset,
    };
  }
});
